import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import GiftIcon from '../../../../icons/gift.svg';

import styles from './Gift.styl';

const cx = classNames.bind(styles);

const Gift = ({ className, count, width, height }) => (
  <div className={cx('Gift', className)}>
    <div className={cx('Gift__icon')}>
      <GiftIcon width={width} height={height} />
    </div>
    {count > 1 && <div className={cx('Gift__count')}>x{count}</div>}
  </div>
);

Gift.defaultProps = {
  className: '',
  width: 24,
  height: 24,
};

Gift.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Gift;
