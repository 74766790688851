import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Button from '../../../Button';

// Icons
import PlusIcon from '../../../../icons/plus.svg';
import MinusIcon from '../../../../icons/minus.svg';

// Styles
import styles from './QuantityControl.styl';

const cx = classNames.bind(styles);

export default function QuantityControl(props) {
  const { className, quantity, onChange, onRemove, max = 100 } = props;

  const min = 1;

  const canDecrement = quantity >= min + 1;
  const canIncrement = quantity <= max - 1;

  const increment = () => {
    onChange(Math.min(quantity + 1, max));
  };

  const decrement = () => {
    onChange(Math.max(quantity - 1, min));
  };

  return (
    <div className={cx('QuantityControl', className)}>
      <Button
        className={cx('QuantityControl__button')}
        type="button"
        onClick={!canDecrement && onRemove ? onRemove : decrement}
        data-test-id='minus'
      >
        <MinusIcon />
      </Button>
      <div className={cx('QuantityControl__display')} data-test-id='quantity'>{quantity}</div>
      <Button
        className={cx('QuantityControl__button')}
        onClick={increment}
        disabled={!canIncrement}
        data-test-id='plus'

      >
        <PlusIcon />
      </Button>
    </div >
  );
}

QuantityControl.defaultProps = {
  className: '',
  quantity: 0,
  onRemove: null,
  max: 100,
};

QuantityControl.propTypes = {
  className: PropTypes.string,
  quantity: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  max: PropTypes.number,
};
